import { html, TemplateResult } from 'lit-element';
import { unsafeHTML } from 'lit-html/directives/unsafe-html';
import { DispatchContext, Dispatcher } from './dispatcher';
import styles from './blocks.css';
import { ContextFieldValue } from '../context-manager';
import classNames from 'classnames';
import { composeCSSProperties } from './utils';

export const blocks = new Dispatcher();

export interface IBlock {
    blockType: string;
    options: any;
}

interface ITemplateField {
    id: string;
    label: string;
    name: string;
    field_type: string;
    choices: string[];
    help_text: string;
    default_value: string | boolean | string[];
    is_required: boolean;
    read_only: boolean;
    display_inline?: boolean;
}

export enum FieldType {
    HEADING = 'Heading',
    SPACER = 'Spacer',
    BOOLEAN = 'BooleanField',
    CHAR = 'CharField',
    SSN = 'SSNField',
    TEXTAREA = 'TextareaField',
    CHOICE = 'ChoiceField',
    PROVINCE = 'ProvinceField',
    PROVINCE_CODE = 'ProvinceCodeField',
    DROPDOWN_CHOICE = 'DropdownChoiceField',
    DATE = 'DateField',
    DATETIME = 'DateTimeField',
    TIME = 'TimeField',
    EMAIL = 'EmailField',
    FLOAT = 'FloatField',
    DECIMAL = 'DecimalField',
    INTEGER = 'IntegerField',
    MULTIPLE_CHOICE = 'MultipleChoiceField',
    NULL_BOOLEAN = 'NullBooleanField',
    URL = 'URLField',
    FILE = 'FileField',
}

export const getTemplateFieldName = (name: string, type: string) => `${name}:${type}`;

export const extractTemplateFieldName = (fieldName: string) => fieldName.split(':');

const Label = (options: ITemplateField, content: TemplateResult | string) => {
    const classes = classNames([
        styles.templateFieldLabel,
        {
            [styles.templateFieldLabelRequired]:
                options.is_required && !options.read_only,
        },
    ]);
    return html`
        <label class="${styles.templateField}">
            <span class="${classes}">${unsafeHTML(options.label)}</span>
            ${content}
        </label>
    `;
};

const BaseInputField = (
    type: string,
    options: ITemplateField,
    value: ContextFieldValue
) =>
    html`
        <input
            class="${styles.templateFieldInput}"
            type="${type}"
            name="${options.name}"
            placeholder="${options.help_text}"
            value="${value || options.default_value}"
            ?required="${options.is_required}"
            ?readonly="${options.read_only}"
            ?disabled="${options.read_only}"
        />
    `;

const InputField = (type: string, options: ITemplateField, value: ContextFieldValue) =>
    Label(options, BaseInputField(type, options, value));

const SSN_REGEX =
    '^[A-Za-z]{6}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{3}[A-Za-z]{1}$';

const SSNField = (options: ITemplateField, value: ContextFieldValue) =>
    Label(
        options,
        html`
            <input
                class="${styles.templateFieldInput}"
                type="text"
                pattern="${SSN_REGEX}"
                name="${options.name}"
                placeholder="${options.help_text}"
                value="${value || options.default_value}"
                ?required="${options.is_required}"
                ?readonly="${options.read_only}"
                ?disabled="${options.read_only}"
            />
        `
    );

const FloatField = (options: ITemplateField, value: ContextFieldValue) =>
    Label(
        options,
        html`
            <input
                class="${styles.templateFieldInput}"
                type="number"
                step="any"
                name="${options.name}"
                placeholder="${options.help_text}"
                value="${value || options.default_value}"
                ?required="${options.is_required}"
                ?readonly="${options.read_only}"
                ?disabled="${options.read_only}"
            />
        `
    );

const TextAreaField = (options: ITemplateField, value: ContextFieldValue) =>
    Label(
        options,
        html`
            <textarea
                class="${styles.templateFieldInput}"
                name="${options.name}"
                placeholder="${options.help_text}"
                value="${value || options.default_value}"
                ?required="${options.is_required}"
                ?readonly="${options.read_only}"
                ?disabled="${options.read_only}"
                rows="2"
            />
        `
    );

const DropdownChoiceField = (options: ITemplateField, value: ContextFieldValue) => {
    const Option = (itemValue: string, item: string) => html`
        <option value="${itemValue}" ?selected="${itemValue === value}">${item}</option>
    `;
    let choices = options.choices.map((choice: string) => Option(choice, choice));
    if (!options.is_required) {
        choices = [Option('', '---'), ...choices];
    }
    return Label(
        options,
        html`
            <select
                class="${styles.templateFieldSelect}"
                name="${options.name}"
                ?disabled="${options.read_only}"
            >
                ${choices}
            </select>
        `
    );
};

const BaseToggleInput =
    (type: string) => (options: ITemplateField, value: ContextFieldValue) => {
        const toggleInput = html`
            <input
                class="${styles.templateFieldToggle}"
                type="${type}"
                name="${options.name}"
                value="${options.label}"
                placeholder="${options.help_text}"
                ?checked="${value || options.default_value}"
                ?required="${options.is_required}"
                ?readonly="${options.read_only}"
                ?disabled="${options.read_only}"
                data-type="${options.field_type}"
            />
        `;
        return html`
            <div class="${styles.toggleInput}">${Label(options, toggleInput)}</div>
        `;
    };

const BaseToggleChoice = (type: string) => {
    const ToggleInput = BaseToggleInput(type);
    return (options: ITemplateField, value: ContextFieldValue = []) => {
        const itemIn = (values: ContextFieldValue, item: string): boolean =>
            (values as string[]).includes(item);
        const choices = options.choices.map((item) =>
            ToggleInput(
                {
                    ...options,
                    label: item,
                    default_value: itemIn(options.default_value, item),
                },
                itemIn(value, item)
            )
        );
        const classes = classNames([
            styles.templateFieldToggleContainerItems,
            { [styles.templateFieldToggleContainerItemsInline]: options.display_inline },
        ]);
        return html`
            <div class="${styles.templateFieldToggleContainer}">
                ${Label(options, '')}
                <div class="${classes}">${choices}</div>
            </div>
        `;
    };
};

const CheckboxField = BaseToggleInput('checkbox');

const ChoiceField = BaseToggleChoice('radio');

const MultipleChoiceField = BaseToggleChoice('checkbox');

const FallbackInput = (options: ITemplateField) =>
    Label(options, html`<div>This field is not implemented yet</div>`);

blocks.registerComponent(
    'TEMPLATE_FIELD',
    (rawOptions: ITemplateField, context: DispatchContext) => {
        const fieldValue = context.manager.getDataField(rawOptions.name);
        const options: ITemplateField = {
            ...rawOptions,
            name: getTemplateFieldName(rawOptions.name, rawOptions.field_type),
        };
        switch (options.field_type) {
            //case FieldType.HEADING: // TODO: Check if needed
            //case FieldType.SPACER: // TODO: Check if needed
            case FieldType.BOOLEAN:
                return CheckboxField(options, fieldValue);
            case FieldType.CHAR:
                return InputField('text', options, fieldValue);
            case FieldType.SSN:
                return SSNField(options, fieldValue);
            case FieldType.TEXTAREA:
                return TextAreaField(options, fieldValue);
            case FieldType.CHOICE:
                return ChoiceField(options, fieldValue);
            case FieldType.PROVINCE:
            case FieldType.PROVINCE_CODE:
            case FieldType.DROPDOWN_CHOICE:
                return DropdownChoiceField(options, fieldValue);
            case FieldType.DATE:
                return InputField('date', options, fieldValue);
            case FieldType.DATETIME:
                return InputField('datetime-local', options, fieldValue);
            case FieldType.TIME:
                return InputField('time', options, fieldValue);
            case FieldType.EMAIL:
                return InputField('email', options, fieldValue);
            case FieldType.FLOAT:
                return FloatField(options, fieldValue);
            case FieldType.DECIMAL:
            case FieldType.INTEGER:
                return InputField('number', options, fieldValue);
            case FieldType.MULTIPLE_CHOICE:
                return MultipleChoiceField(options, fieldValue);
            //case FieldType.NULL_BOOLEAN: // TODO: Check if needed
            case FieldType.URL:
                return InputField('url', options, fieldValue);
            //case FieldType.FILE: // TODO: Check if needed
            default:
                return FallbackInput(options);
        }
    }
);

interface IImage {
    media: string;
    alt?: string;
    maxWidth?: string;
    align?: 'left' | 'center' | 'right';
}

blocks.registerComponent('IMAGE', (options: IImage) => {
    const imageAlt = options.alt || 'Image';
    const properties = composeCSSProperties({
        '--Image-maxWidth': options.maxWidth,
        '--Image-align': options.align,
    });
    return html`
        <div class="${styles.imageContainer}">
            <img
                class="${styles.image}"
                style="${properties}"
                src="${options.media}"
                alt="${imageAlt}"
            />
        </div>
    `;
});

interface IHeading {
    content: string;
    align?: 'left' | 'center' | 'right';
    isSubtitle?: boolean;
}

blocks.registerComponent('HEADING', (options: IHeading) => {
    const classes = classNames([
        styles.heading,
        { [styles.headingLeft]: options.align === 'left' },
        { [styles.headingRight]: options.align === 'right' },
        { [styles.headingCenter]: options.align === 'center' },
    ]);
    if (options.isSubtitle) {
        return html`<h2 class="${classes}">${options.content}</h2>`;
    }
    return html`<h1 class="${classes}">${options.content}</h1>`;
});

interface IParagraph {
    content: string;
    align?: string;
}

blocks.registerComponent('PARAGRAPH', (options: IParagraph) => {
    const properties = composeCSSProperties({
        '--Paragraph-align': options.align,
    });
    return html`
        <p class="${styles.paragraph}" style="${properties}">
            ${unsafeHTML(options.content)}
        </p>
    `;
});

interface IButton {
    text: string;
    target?: string;
    align?: string;
    isWide?: boolean;
}

blocks.registerComponent('BUTTON', (options: IButton, context: DispatchContext) => {
    const properties = composeCSSProperties({
        '--Button-align': options.align,
    });
    const classes = classNames([
        styles.button,
        { [styles.buttonIsWide]: options.isWide },
    ]);
    const action = () =>
        context.dispatchAction({
            type: 'JUMP_TO_PAGE',
            payload: { target: options.target },
        });
    return html`
        <div class="${styles.buttonWrapper}" style="${properties}">
            <button class="${classes}" @click="${action}">${options.text}</button>
        </div>
    `;
});
