import { StateChart } from '.';

export enum DataAPIState {
    NOT_STARTED,
    TOKEN_MISSING,
    LOADING,
    SUCCESS,
    TOKEN_INVALID,
}

export enum DataAPIEvent {
    FETCH,
    SUCCESS,
    ERROR,
}

export type DataAPIMachine = StateChart<DataAPIState, DataAPIEvent>;

export const getDataAPIMachine = (): DataAPIMachine =>
    new StateChart<DataAPIState, DataAPIEvent>({
        initial: DataAPIState.NOT_STARTED,
        states: {
            [DataAPIState.NOT_STARTED]: {
                on: {
                    [DataAPIEvent.FETCH]: DataAPIState.LOADING,
                    [DataAPIEvent.ERROR]: DataAPIState.TOKEN_MISSING,
                },
            },
            [DataAPIState.TOKEN_MISSING]: {},
            [DataAPIState.LOADING]: {
                on: {
                    [DataAPIEvent.SUCCESS]: DataAPIState.SUCCESS,
                    [DataAPIEvent.ERROR]: DataAPIState.TOKEN_INVALID,
                },
            },
            [DataAPIState.SUCCESS]: {},
            [DataAPIState.TOKEN_INVALID]: {},
        },
    });
