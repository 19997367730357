import { TemplateResult } from 'lit-html';
import type { ContextManager } from '../context-manager';
import { FallbackComponent } from './common';

interface IRegistry {
    [key: string]: Function;
}

export interface DispatchContext {
    manager: ContextManager;
    dispatchAction: Function;
}

export class Dispatcher {
    private registry: IRegistry;

    private readonly fallbackType: string = 'FALLBACK';

    private readonly fallbackComponent: Function = FallbackComponent;

    public constructor() {
        this.registry = {};
    }

    private getComponent(componentType: string): Function {
        return this.registry[componentType];
    }

    public registerComponent(componentType: string, component: Function): void {
        this.registry[componentType] = component;
    }

    public setFallbackComponent(component: Function) {
        this.registerComponent(this.fallbackType, component);
    }

    private dispatchComponent(componentType: string): Function {
        const fetchedComponent = this.getComponent(componentType);

        if (fetchedComponent === undefined) {
            const fallbackComponent = this.getComponent(this.fallbackType);
            return fallbackComponent || this.fallbackComponent;
        }

        return fetchedComponent;
    }

    public dispatch(
        componentType: string,
        options: any,
        context: DispatchContext
    ): TemplateResult {
        const component = this.dispatchComponent(componentType);
        return component(options, context);
    }
}
