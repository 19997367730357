import classNames from 'classnames';
import { html, TemplateResult } from 'lit-element';
import styles from './common.css';
import type { DispatchContext } from './dispatcher';
import type { ISlot } from './layouts';
import { composeCSSProperties } from './utils';

export interface IPage {
    id: string;
    ordering?: number;
    index?: number;
    layout?: string;
    slots?: ISlot[];
    content?: TemplateResult;
}

export const getPageId = (id: string): string => `page_${id}`;

export const Page = (page: IPage, context: DispatchContext) => {
    const submitPageHandler = (e: SubmitEvent) => {
        e.preventDefault();
        context.dispatchAction({
            type: 'JUMP_TO_PAGE',
            payload: { target: 'NEXT' },
        });
    };
    return html`
        <form id="${getPageId(page.id)}" class="page" @submit="${submitPageHandler}">
            ${page.content}
        </form>
    `;
};

export interface IContainer {
    token?: string;
    pages?: IPage[];
    options?: {
        primaryBackground?: string;
        primaryColor?: string;
        secondaryBackground?: string;
        secondaryColor?: string;
        inputBackground?: string;
        inputColor?: string;
    };
}

export const Container = (
    container: IContainer,
    pageContent: TemplateResult,
    floatingLoader: TemplateResult
) => {
    const properties = composeCSSProperties({
        '--WizardEmbed-primaryBackground': container?.options?.primaryBackground,
        '--WizardEmbed-primaryColor': container?.options?.primaryColor,
        '--WizardEmbed-secondaryBackground': container?.options?.secondaryBackground,
        '--WizardEmbed-secondaryColor': container?.options?.secondaryColor,
        '--WizardEmbed-inputBackground': container?.options?.inputBackground,
        '--WizardEmbed-inputColor': container?.options?.inputColor,
    });
    return html`
        <div id="${container.token}" class="${styles.container}" style="${properties}">
            <div class="${styles.pageContent}">${pageContent}</div>
            <div class="${styles.floatingLoaderContainer}">${floatingLoader}</div>
        </div>
    `;
};

export const FallbackComponent = () => html`
    <div class="${styles.fallback}">This component is not supported</div>
`;

export const Loader = () => html`<div class="${styles.loader}"></div>`;

export const FloatingLoader = (visible: boolean) => {
    const floatingLoaderClasses = classNames([
        styles.floatingLoader,
        { [styles.floatingLoaderIsVisible]: visible },
    ]);
    return html`<div class="${floatingLoaderClasses}">${Loader()}</div>`;
};
