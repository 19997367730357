import { StateChart } from '.';

export enum ActionAPIState {
    IDLE,
    PROCESSING,
}

export enum ActionAPIEvent {
    FETCH,
    DONE,
}

export type ActionAPIMachine = StateChart<ActionAPIState, ActionAPIEvent>;

export const getActionAPIMachine = (): ActionAPIMachine =>
    new StateChart<ActionAPIState, ActionAPIEvent>({
        initial: ActionAPIState.IDLE,
        states: {
            [ActionAPIState.IDLE]: {
                on: {
                    [ActionAPIEvent.FETCH]: ActionAPIState.PROCESSING,
                },
            },
            [ActionAPIState.PROCESSING]: {
                on: {
                    [ActionAPIEvent.DONE]: ActionAPIState.IDLE,
                },
            },
        },
    });
