import { html, property, customElement, TemplateResult } from 'lit-element';
import graphql from 'graphql.js';
import { ActionHandler, ActionType, getActionHandler } from '../../action-manager';
import { renderPage, renderUI } from '../../components';
import { FloatingLoader, getPageId, IContainer, Loader } from '../../components/common';
import { extractTemplateFieldName, FieldType } from '../../components/blocks';
import { ContextManager, getContextManager, IRecordContext } from '../../context-manager';
import { APIAction, APIActionType, getContainer } from '../../graphql';
import { LightElement } from '../base-component';
import { getPageManager, PageManager } from './page-manager';
import styles from './styles.css';
import {
    DataAPIState,
    DataAPIEvent,
    DataAPIMachine,
    getDataAPIMachine,
} from '../../statechart/data-api';
import {
    ActionAPIState,
    ActionAPIEvent,
    ActionAPIMachine,
    getActionAPIMachine,
} from '../../statechart/action-api';

/** The main component that contain everithing needed for the wizard to run. */
@customElement('wizard-embed')
export class WizardEmbed extends LightElement {
    /** The token is used to fetch all the data from the API. */
    @property({ type: String })
    token: string = '';

    /** The apiUrl define where to call the server for data. */
    @property({ type: String })
    apiUrl: string = process.env.API_ENDPOINT;

    private containerData: IContainer = {};

    private contextManager: ContextManager;

    private actionHandler: ActionHandler;

    private pageManager: PageManager;

    private dataAPIMachine: DataAPIMachine;

    private actionAPIMachine: ActionAPIMachine;

    private graph: Function;

    public connectedCallback() {
        super.connectedCallback();
        // Setup
        this.contextManager = getContextManager();
        this.pageManager = getPageManager();
        this.actionHandler = getActionHandler();
        this.dataAPIMachine = getDataAPIMachine();
        this.actionAPIMachine = getActionAPIMachine();
        this.graph = graphql(this.apiUrl);
        // Configure callbacks
        this.pageManager.addHandler(() => this.requestUpdate());
        this.actionHandler.addHandler((action: ActionType) => this.handleAction(action));
        this.dataAPIMachine.addHandler(() => this.requestUpdate());
        this.actionAPIMachine.addHandler(() => this.requestUpdate());
        // Call API
        this.queryData();
    }

    public disconnectedCallback() {
        super.disconnectedCallback();
        this.pageManager.removeHandler();
        this.actionHandler.removeHandler();
        this.dataAPIMachine.removeHandler();
        this.actionAPIMachine.removeHandler();
    }

    private get state() {
        return this.dataAPIMachine.state;
    }

    private transition(event: DataAPIEvent) {
        this.dataAPIMachine.transition(event);
    }

    private get actionState() {
        return this.actionAPIMachine.state;
    }

    private dispatchAction(action: ActionType) {
        return this.actionHandler.dispatch(action);
    }

    private handleAction(action: ActionType) {
        switch (action.type) {
            case 'JUMP_TO_PAGE':
                if (!this.isValidPageForm()) return;
                this.updateFormContext();
                if (action.payload.target === 'NEXT') {
                    this.pageManager.setNextPage();
                    return;
                }
                if (action.payload.target === 'PREV') {
                    this.pageManager.setPrevPage();
                    return;
                }
                this.pageManager.setPageById(action.payload.target);
                return;

            case 'SAVE_RECORD':
                this.saveRecord(action.payload);
                return;
        }
    }

    private async queryData() {
        if (this.token) {
            try {
                this.transition(DataAPIEvent.FETCH);
                const response = await getContainer(this.graph, this.token);
                const { pages = [], ...container } = response;
                this.pageManager.pages = pages;
                this.containerData = container;
                this.contextManager.updateContext({
                    containerToken: container.token,
                });
                this.transition(DataAPIEvent.SUCCESS);
            } catch (error) {
                this.transition(DataAPIEvent.ERROR);
            }
        } else {
            this.transition(DataAPIEvent.ERROR);
        }
    }

    private async saveRecord(payload: IRecordContext) {
        const recordAction = payload.id
            ? APIActionType.UPDATE_RECORD
            : APIActionType.CREATE_RECORD;
        try {
            this.actionAPIMachine.transition(ActionAPIEvent.FETCH);
            const res = await APIAction(
                this.graph,
                this.contextManager,
                recordAction,
                payload
            );
            if (res.action === APIActionType.CREATE_RECORD) {
                this.contextManager.updateContext({
                    record: { id: res.response.id },
                });
            }
        } catch (error) {
            console.log(error);
        } finally {
            this.actionAPIMachine.transition(ActionAPIEvent.DONE);
        }
    }

    private isValidPageForm(): boolean {
        return this.getPageForm().reportValidity();
    }

    private getPageForm(): HTMLFormElement {
        const activePage = this.pageManager.activePage;
        const pageId = getPageId(activePage.id);
        const form: HTMLFormElement = this.$(`#${pageId}`) as HTMLFormElement;
        return form;
    }

    private extractFormData(form: HTMLFormElement) {
        const data = {};
        for (const [key, value] of new FormData(form)) {
            const [fieldName, fieldType] = extractTemplateFieldName(key);
            switch (fieldType) {
                case FieldType.BOOLEAN:
                    break; // Handled separately
                case FieldType.MULTIPLE_CHOICE:
                    data[fieldName] = [...(data[fieldName] || []), value];
                    break;
                default:
                    data[fieldName] = value;
                    break;
            }
        }
        // Get checkboxes separately to extract both true and false values
        form.querySelectorAll(`[data-type="${FieldType.BOOLEAN}"]`).forEach(
            (checkbox: HTMLInputElement) => {
                const [fieldName, fieldType] = extractTemplateFieldName(checkbox.name);
                if (fieldType !== FieldType.BOOLEAN) {
                    return;
                }
                data[fieldName] = checkbox.checked;
            }
        );
        return data;
    }

    private updateFormContext() {
        const form = this.getPageForm();
        const updatedFormData = this.extractFormData(form);
        this.contextManager.updateContext({
            record: { data: updatedFormData },
        });
        this.dispatchAction({
            type: 'SAVE_RECORD',
            payload: this.contextManager.getRecord(),
        });
    }

    public render(): TemplateResult {
        return html`<div class="${styles.embed}">${this.renderContent()}</div>`;
    }

    public renderFloatingLoader(): TemplateResult {
        switch (this.actionState) {
            case ActionAPIState.IDLE:
                return FloatingLoader(false);
            case ActionAPIState.PROCESSING:
                return FloatingLoader(true);
        }
    }

    public renderContent(): TemplateResult {
        switch (this.state) {
            case DataAPIState.NOT_STARTED:
            case DataAPIState.LOADING:
                return this.renderLoading();
            case DataAPIState.SUCCESS:
                return this.renderContainer();
            case DataAPIState.TOKEN_INVALID:
                return this.renderError('The token specified is not valid');
            case DataAPIState.TOKEN_MISSING:
                return this.renderError('Please specify a token');
        }
    }

    private renderLoading(): TemplateResult {
        return Loader();
    }

    private renderContainer(): TemplateResult {
        return renderUI(
            this.containerData,
            this.renderPage(),
            this.renderFloatingLoader()
        );
    }

    private renderPage(): TemplateResult {
        return renderPage(this.pageManager.activePage, {
            manager: this.contextManager,
            dispatchAction: (action: ActionType) => this.dispatchAction(action),
        });
    }

    private renderError(message: string): TemplateResult {
        return html`<div class="${styles.error}">${message}</div>`;
    }
}
