import { TemplateResult } from 'lit-html';
import { IBlock, blocks } from './blocks';
import { IContainer, Container, IPage, Page } from './common';
import type { DispatchContext } from './dispatcher';
import { ISlot, layouts } from './layouts';

export const renderBlocks = (
    blockData: IBlock[],
    context: DispatchContext
): TemplateResult[] =>
    blockData.map(({ blockType, options }) =>
        blocks.dispatch(blockType, options, context)
    );

export const renderPage = (page: IPage, context: DispatchContext): TemplateResult => {
    const slots = page?.slots.map(
        (slot: ISlot): ISlot => ({
            slotType: slot.slotType,
            content: renderBlocks(slot.blocks, context),
        })
    );
    const layout = layouts.dispatch(page?.layout, slots, context);
    return Page({ id: page?.id, content: layout }, context);
};

export const renderUI = (
    container: IContainer,
    pageContent: TemplateResult,
    floatingLoader: TemplateResult
): TemplateResult => Container(container, pageContent, floatingLoader);
