import { html } from 'lit-element';

export const PrivacyText = () => html`
<div>
<b>INFORMATIVA
SUL TRATTAMENTO DEI DATI PERSONALI - ART. 13 D.LGS. 196/2003 E
REGOLAMENTO UE 2016/679</b></p>
<p><br/>

</p>
<p>Gentile
Sig./Sig.ra
</p>
<p>nel
rispetto dell'art. 13 del Regolamento UE 2016/679 - Regolamento
Generale Protezione Dati (RGPD), la informiamo sul trattamento dei
dati personali effettuato per attivare e gestire il rapporto di
fornitura di servizi instaurato tra lei e la nostra società:</p>
<p><b>STEM5
S.R.L.</b></p>
<p>in
qualità di titolare del trattamento dei suoi dati.</p>
<p><br/>

</p>
<p><b>Finalità
del trattamento</b></p>
<p>La
raccolta ed il trattamento dei dati personali sono effettuati per le
seguenti finalità:</p>
<p>-
adempimento obblighi contrattuali, di legge e finalità
amministrativo-contabile. Ai fini dell’applicazione delle
disposizioni in materia di protezione dei dati personali,</p>
<p><br/>

</p>
<p>I
trattamenti effettuati per finalità amministrativo-contabili sono
quelli connessi allo svolgimento delle attività di natura
organizzativa, amministrativa, finanziaria e contabile, a prescindere
dalla natura dei dati trattati.</p>
<p><br/>

</p>
<p><b>Modalità
del trattamento</b></p>
<p>I
suoi dati saranno trattati con procedure prevalentemente
informatizzate e potranno essere conosciuti da nostri dipendenti
autorizzati al trattamento e/o da società di nostra fiducia che
svolgono, per nostro conto, in qualità di responsabili del
trattamento, alcune attività tecniche ed organizzative, quali
società di servizi amministrativi, contabili e fiscali o
informatici. I suoi dati potranno essere inoltre comunicati alle
seguenti categorie di soggetti: amministrazioni pubbliche (Agenzia
delle Entrate, INPS, INAIL,...) per l'adempimento di obblighi di
legge.</p>
<p><br/>

</p>
<p>Nell'ambito
del trattamento effettuato dalla nostra società i suoi dati
potrebbero essere trasferiti verso paesi terzi in conseguenza
dell'utilizzo di risorse di cloud computing a fini di archiviazione e
conservazione dei dati. In tali casi i suoi dati saranno protetti
mediante l'utilizzo di tecniche di pseudonimizzazione e cifratura.</p>
<p><br/>

</p>
<p><b>Natura
obbligatoria o facoltativa del conferimento dei dati e diritti
dell'interessato</b></p>
<p>Il
conferimento dei suoi dati personali è necessario ed obbligatorio
per l'adempimento degli obblighi contrattuali e normativi connessi
alla fornitura dei nostri servizi: il mancato rilascio, anche in
parte, dei suoi dati personali precluderebbe l'instaurazione del
rapporto in quanto non ne permetterebbe la sua corretta gestione.</p>
<p>Il
RGPD le garantisce i seguenti diritti:</p>
<p>-
ottenere dal titolare del trattamento la conferma che sia o meno in
corso un trattamento di dati personali che la riguardano e, in tal
caso, ottenere l'accesso ai dati personali e alle informazioni
previste dall'art. 15 del regolamento UE n. 679 del 2016;</p>
<p>-
ottenere dal titolare del trattamento la rettifica dei dati personali
inesatti che la riguardano;</p>
<p>-
ottenere la cancellazione dei dati personali che la riguardano
laddove questi non siano più necessari rispetto alle finalità per
le quali sono stati raccolti o altrimenti trattati, ovvero laddove
ricorrano a ulteriori condizioni di cui all'art. 17 regolamento UE n.
679/2016 e sempre che non ricorrano le condizioni di cui all'art. 17,
paragrafo 3, regolamento UE n. 679/2016;</p>
<p>-
ottenere dal titolare la limitazione del trattamento quando: a)
l'interessato contesti l'esattezza dei dati personali, per il periodo
necessario al titolare del trattamento per verificare l'esattezza di
tali dati personali; b) il trattamento risulti illecito ma
l'interessato stesso si opponga alla cancellazione e chieda invece
l'applicazione di misure di limitazione oppure chieda che il
trattamento degli stessi avvenga per l'accertamento o difesa di un
suo diritto in sede giudiziaria;<br/>
- ricevere in un formato
strutturato, di uso comune e leggibile da dispositivo automatico i
dati personali che lo riguardano; nel caso dell'esercizio di tale
diritto sarà sua facoltà chiedere che il titolare del trattamento
trasmetta i suddetti dati direttamente ad un altro responsabile del
trattamento</p>
<p>-
opporsi al trattamento che si basi su un processo decisionale
automatizzato relativo a persona fisica, ove questo includa anche la
profilazione;</p>
<p>-
qualora il trattamento si basi sul consenso, ne sarà possibile la
revoca in qualsiasi momento; in tal caso, peraltro, tutte le attività
compiute prima della revoca rimarranno valide ed efficaci.</p>
<p>In
merito all'esercizio di tali diritti lei potrà rivolgersi a:</p>
<p><br/>

</p>
<p><b>Titolare
del trattamento</b></p>
<p>STEM5
S.R.L.</p>
<p>VIA
SAN MARCO 16 - 20121 MILANO MI</p>
<p>Codice
fiscale 08992760960 Partita IVA 08992760960</p>
<p>Telefono
0283429550-51</p>
<p>E-mail
privacy@stem5</p>
<p><br/>

</p>
<p>Resta
infine fermo il suo diritto di rivolgersi al Garante Privacy, anche
attraverso la presentazione di un reclamo, ove ritenuto necessario,
per la tutela dei suoi dati personali e dei suoi diritti.</p>
<p>Per
qualsiasi questione attinente al trattamento dei suoi dati personali,
può rivolgersi alla nostra società, quale titolare del trattamento,
con sede in VIA SAN MARCO 16 - 20121 MILANO MI</p>
<p>Telefono
0283429550-51</p>
<p><br/>

</p>
<p><b>Conservazione
dei dati</b></p>
<p>I
dati personali che lei ci fornirà saranno conservati a fini di
esecuzione del servizio pattuito e verranno conservati per il tempo
necessario all'espletamento dello stesso. La conservazione potrà
avvenire mediante:</p>
<p>-
memorizzazione all'interno dei sistemi hardware del titolare del
trattamento o di suoi responsabili;</p>
<p>-
archiviazione a norma del Codice dell'Amministrazione Digitale; in
tal caso il titolare ricorrerà esclusivamente a soggetti accreditati
ai sensi dell'art. 29 CAD, laddove non dovesse essere svolta sui
nostri sistemi applicativi.</p>
<p><br/>

</p>
<p>MILANO 1/1/22</p>
</div>
`;
