export class CallbackHandler {
    private _callback: Function;

    public get callback(): Function {
        const nullCallback = () => null;
        return this._callback || nullCallback;
    }

    public addHandler(callback: Function) {
        this._callback = callback;
    }

    public removeHandler() {
        this._callback = undefined;
    }
}
