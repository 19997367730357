import imgHubaqoRecord from './img/illustrations/hubaqo-record.svg';
import imgHubaqoHome from './img/illustrations/hubaqo-home.svg';
import imgHubaqoList from './img/illustrations/hubaqo-list.svg';
import imgTeam from './img/photos/imgTeam.png';
import imgManCall from './img/photos/ImgManCall.png';
import type { IBlock } from './components';

export const siteData: IBlock[] = [
    {
        type: 'Navbar',
        options: {
            isOnTop: false,
        },
        children: [
            {
                type: 'GhostButton',
                options: {
                    text: 'Login',
                },
                actions: {
                    onClick: {
                        type: 'SWITCH',
                        payload: {},
                    },
                },
            },
            {
                type: 'GhostButton',
                options: {
                    text: 'Contattaci',
                },
                actions: {
                    onClick: {
                        type: 'POPUP',
                        payload: {
                            action: 'OPEN',
                            popup: 'CONTACTS',
                        },
                    },
                },
            },
        ],
    },

    {
        type: 'Hero',
        children: [
            {
                type: 'Section',
                options: {
                    align: 'left',
                    isWide: true,
                    isShort: true,
                },
                children: [
                    {
                        type: 'Row',
                        options: {
                            reverse: false,
                        },
                        children: [
                            {
                                type: 'ContentBlock',
                                children: [
                                    {
                                        type: 'Title',
                                        options: {
                                            text: `
                                                La miglior piattaforma di raccolta lead
                                                semplice da configurare e facile da usare
                                            `,
                                        },
                                    },
                                    {
                                        type: 'Text',
                                        options: {
                                            text: `
                                                hubaqo ti permette di gestire tutto
                                                il tuo lavoro in un unico luogo.
                                            `,
                                        },
                                    },
                                    {
                                        type: 'Button',
                                        options: {
                                            text: 'Inizia ora',
                                        },
                                        actions: {
                                            onClick: {
                                                type: 'POPUP',
                                                payload: {
                                                    action: 'OPEN',
                                                    popup: 'CONTACTS',
                                                },
                                            },
                                        },
                                    },
                                ],
                            },
                            {
                                type: 'ImageBlock',
                                options: {
                                    src: imgManCall,
                                    alt: `
                                        La miglior piattaforma di raccolta lead
                                        semplice da configurare e facile da usare
                                    `,
                                },
                            },
                        ],
                    },
                ],
            },
        ],
    },

    {
        type: 'Section',
        options: {
            align: 'left',
            isWide: true,
            isShort: true,
        },
        children: [
            {
                type: 'Row',
                options: {
                    reverse: true,
                },
                children: [
                    {
                        type: 'ImageBlock',
                        options: {
                            src: imgHubaqoRecord,
                            alt: 'hubaqo nasce per soddisfare le tue esigenze',
                        },
                    },
                    {
                        type: 'ContentBlock',
                        children: [
                            {
                                type: 'Title',
                                options: {
                                    text: 'hubaqo nasce per soddisfare le tue esigenze',
                                },
                            },
                            {
                                type: 'Text',
                                options: {
                                    text: `
                                        Che tu stia monitorando o raccogliendo nuovi
                                        lead, hubaqo è completamente personalizzabile
                                        in modo che il tuo team possa iniziare subito
                                        a usarlo.
                                    `,
                                },
                            },
                            {
                                type: 'Button',
                                options: {
                                    text: 'Contattaci',
                                },
                                actions: {
                                    onClick: {
                                        type: 'POPUP',
                                        payload: {
                                            action: 'OPEN',
                                            popup: 'CONTACTS',
                                        },
                                    },
                                },
                            },
                        ],
                    },
                ],
            },
        ],
    },

    {
        type: 'Section',
        options: {
            align: 'right',
            isWide: true,
            isShort: true,
        },
        children: [
            {
                type: 'Row',
                options: {
                    reverse: false,
                },
                children: [
                    {
                        type: 'ImageBlock',
                        options: {
                            src: imgTeam,
                            alt: 'Fornisci al tuo team  di vendita strumenti per risparmiare tempo',
                        },
                    },
                    {
                        type: 'ContentBlock',
                        children: [
                            {
                                type: 'Title',
                                options: {
                                    text: 'Fornisci al tuo team  di vendita strumenti per risparmiare tempo',
                                },
                            },
                            {
                                type: 'Text',
                                options: {
                                    text: `
                                        Offri al tuo team di vendita gli strumenti
                                        per concludere più affari con meno lavoro
                                        e accelerare i tuoi processi di vendita.
                                    `,
                                },
                            },
                            {
                                type: 'Button',
                                options: {
                                    text: 'Contattaci',
                                },
                                actions: {
                                    onClick: {
                                        type: 'POPUP',
                                        payload: {
                                            action: 'OPEN',
                                            popup: 'CONTACTS',
                                        },
                                    },
                                },
                            },
                        ],
                    },
                ],
            },
        ],
    },

    {
        type: 'Section',
        options: {
            align: 'left',
            isWide: true,
            isShort: true,
        },
        children: [
            {
                type: 'Row',
                options: {
                    reverse: true,
                },
                children: [
                    {
                        type: 'ImageBlock',
                        options: {
                            src: imgHubaqoHome,
                            alt: "Controlla come vanno le cose a colpo d'occhio",
                        },
                    },
                    {
                        type: 'ContentBlock',
                        children: [
                            {
                                type: 'Title',
                                options: {
                                    text: "Controlla come vanno le cose a colpo d'occhio",
                                },
                            },
                            {
                                type: 'Text',
                                options: {
                                    text: `
                                        Visualizza all'istante l'intero processo
                                        di vendita con una dashboard intuitiva.
                                    `,
                                },
                            },
                            {
                                type: 'Button',
                                options: {
                                    text: 'Contattaci',
                                },
                                actions: {
                                    onClick: {
                                        type: 'POPUP',
                                        payload: {
                                            action: 'OPEN',
                                            popup: 'CONTACTS',
                                        },
                                    },
                                },
                            },
                        ],
                    },
                ],
            },
        ],
    },

    {
        type: 'Section',
        options: {
            align: 'center',
            isWide: true,
            isShort: true,
        },
        children: [
            {
                type: 'Row',
                options: {
                    narrow: true,
                    stretch: true,
                },
                children: [
                    {
                        type: 'Card',
                        children: [
                            {
                                type: 'Title',
                                options: {
                                    text: 'Rimani connesso ovunque vai',
                                },
                            },
                            {
                                type: 'Text',
                                options: {
                                    text: `
                                        Supervisiona il flusso delle tue attività
                                        in modo efficiente e migliora i tempi di
                                        risposta. Porta il tuo ufficio con te e
                                        lavora sempre e ovunque.
                                    `,
                                },
                            },
                        ],
                    },
                    {
                        type: 'Card',
                        children: [
                            {
                                type: 'Title',
                                options: {
                                    text: 'Automatizza il tuo flusso di lavoro',
                                },
                            },
                            {
                                type: 'Text',
                                options: {
                                    text: `
                                        Evita le attività ripetitive grazie a potenti
                                        funzionalità di automazione. Con la visione di
                                        un calendario personalizzato, imposta avvisi
                                        di notifica e promemoria in modo da essere
                                        sempre aggiornato.
                                    `,
                                },
                            },
                        ],
                    },
                    {
                        type: 'Card',
                        children: [
                            {
                                type: 'Title',
                                options: {
                                    text: 'Integrazioni CRM con un click',
                                },
                            },
                            {
                                type: 'Text',
                                options: {
                                    text: `
                                        Non dovrai più usare diversi strumenti.
                                        Porta i tuoi dati in un unico luogo
                                        collegandoti a hubaqo
                                    `,
                                },
                            },
                        ],
                    },
                ],
            },
        ],
    },

    {
        type: 'Section',
        options: {
            align: 'right',
            isWide: true,
            isShort: true,
        },
        children: [
            {
                type: 'Row',
                options: {
                    reverse: false,
                },
                children: [
                    {
                        type: 'ImageBlock',
                        options: {
                            src: imgHubaqoList,
                            alt: 'Connettiti con i tuoi clienti ovunque si trovino',
                        },
                    },
                    {
                        type: 'ContentBlock',
                        children: [
                            {
                                type: 'Title',
                                options: {
                                    text: 'Connettiti con i tuoi clienti ovunque si trovino',
                                },
                            },
                            {
                                type: 'List',
                                options: {
                                    list: [
                                        `Raggiungi i clienti su tutti i canali:
                                            telefono, e-mail, chat dal vivo e social media`,
                                        'Ricevi notifiche in tempo reale ovunque tu sia',
                                        `Misura l'efficacia della comunicazione con i
                                            tuoi clienti e trova il momento e il canale
                                            migliore per raggiungere i tuoi clienti`,
                                    ],
                                },
                            },
                            {
                                type: 'Button',
                                options: {
                                    text: 'Contattaci',
                                },
                                actions: {
                                    onClick: {
                                        type: 'POPUP',
                                        payload: {
                                            action: 'OPEN',
                                            popup: 'CONTACTS',
                                        },
                                    },
                                },
                            },
                        ],
                    },
                ],
            },
        ],
    },

    {
        type: 'Footer',
        options: {
            privacyText: 'Informativa Privacy',
            footerInfo: [
                {
                    text: 'STEM5 SRL',
                    url: 'http://stem5.com/',
                    target: '_blank',
                },
                {
                    text: 'Via San Marco 16, Milano',
                    url: '',
                },
                {
                    text: '+39 02 834 295 51',
                    url: 'tel:+390283429551',
                },
                {
                    text: 'info@stem5.com',
                    url: 'mailto:info@stem5.com',
                },
            ],
        },
        actions: {
            onClick: {
                type: 'POPUP',
                payload: {
                    action: 'OPEN',
                    popup: 'PRIVACY',
                },
            },
        },
    },
];
