/** @module myModule */

import { LitElement } from 'lit-element';

/** This class allow to use external css within the inner DOM.
 *
 * Used for styling with CSS Modules.
 */
export class LightElement extends LitElement {
    createRenderRoot() {
        // Skip Shadow DOM Generation Shadow DOM
        return this;
    }

    /** An helper method to query elements within the inner DOM. */
    $(selector: string): HTMLElement {
        return this.querySelector(selector);
    }
}
