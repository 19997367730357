import { IRecordContext } from '../context-manager';
import { CallbackHandler } from '../helpers';

type TAction<T extends string, P> = {
    type: T;
    payload: P;
};

export type JumpToPage = TAction<'JUMP_TO_PAGE', { target: string | 'NEXT' | 'PREV' }>;
export type SaveRecord = TAction<'SAVE_RECORD', IRecordContext>;

export type ActionType = JumpToPage | SaveRecord;

export class ActionHandler extends CallbackHandler {
    public dispatch(action: ActionType) {
        this.callback(action);
    }
}

export const getActionHandler = () => new ActionHandler();
