import { html, TemplateResult } from 'lit-html';
import { Navbar, Hero, Row, ContentBlock, Footer, Section, ImageBlock } from './layout';
import {
    Title,
    Text,
    Subtitle,
    Card,
    List,
    Topic,
    Button,
    FormButton,
    ScrollHint,
    GhostButton,
    ScrollButtons,
} from './ui';

const FallbackComponent = (type: string) => () =>
    html`<div>Component '${type}' not supported</div>`;

const components = {
    // Layout
    Navbar,
    Hero,
    Section,
    Row,
    ContentBlock,
    Footer,
    ImageBlock,
    // UI
    Title,
    Text,
    Subtitle,
    Card,
    List,
    Topic,
    Button,
    FormButton,
    ScrollHint,
    GhostButton,
    ScrollButtons,
};

type BlockType = keyof typeof components;

type BlockEventType = 'SWITCH' | 'POPUP';

export interface IBlockEvent {
    type: BlockEventType;
    payload?: any;
}

export interface IBlock {
    type: BlockType;
    options?: any;
    children?: IBlock[];
    actions?: {
        [key: string]: IBlockEvent;
    };
}

export const triggerEvent = (target: EventTarget, event: IBlockEvent) => {
    target.dispatchEvent(
        new CustomEvent('block-event', { detail: event, bubbles: true, composed: true })
    );
};

const buildActions = (actions: IBlock['actions']) => {
    const actionsMap = {};
    for (const [key, event] of Object.entries(actions)) {
        actionsMap[key] = (e: Event) => triggerEvent(e.target, event);
    }
    return actionsMap;
};

export const buildUI = (blocks: IBlock[]): TemplateResult[] =>
    (blocks || []).map((block: IBlock) => {
        const component = components[block.type] || FallbackComponent(block.type);
        const children = block.children;
        const childrenUI = buildUI(children);
        const actions = buildActions(block.actions || {});
        const params = {
            ...block.options,
            ...actions,
        };
        return component(params, childrenUI);
    });
