import { IPage } from '../../components/common';
import { CallbackHandler } from '../../helpers';

export class PageManager extends CallbackHandler {
    public pages: IPage[];

    public constructor() {
        super();
        this.pages = [];
        this.currentIndex = 0;
    }

    private _currentIndex: number;

    private get currentIndex() {
        return this._currentIndex;
    }

    private set currentIndex(value: number) {
        this._currentIndex = value;
        this.callback();
    }

    public get activePage(): IPage {
        return this.pages[this.clampPageIndex(this.currentIndex)];
    }

    private clampPageIndex(index: number): number {
        return Math.max(0, Math.min(index, this.pages.length));
    }

    public setPageById(id: string) {
        this.currentIndex = this.pages.findIndex((pageData) => pageData.id === id);
    }

    public setNextPage() {
        if (this.currentIndex + 1 < this.pages.length) {
            this.currentIndex++;
        }
    }

    public setPrevPage() {
        if (this.currentIndex > 0) {
            this.currentIndex--;
        }
    }
}

export const getPageManager = () => new PageManager();
