import classNames from 'classnames';
import { html, TemplateResult } from 'lit-element';
import styles from './popup.css';

export const CloseButton = (action: Function) => html`
    <div class="${styles.closeButton}" @click="${action}">
        <span class="iconify ${styles.closeIcon}" data-icon="ci:off-outline-close"></span>
    </div>
`;

export const Input = (
    label: string,
    name: string,
    type: string = 'text',
    required: boolean = true
) => html`
    <label class="${styles.input}">
        <input
            class="${styles.inputField}"
            name="${name}"
            placeholder=" "
            type="${type}"
            ?required="${required}"
        />
        <span class="${styles.inputLabel}">${label}</span>
    </label>
`;

export const Popup = (
    content: TemplateResult | TemplateResult[],
    isOpen: boolean,
    closeAction: Function
) => {
    const classes = classNames([styles.container, { [styles.hiddenPopup]: !isOpen }]);
    return html`
        <div class="${classes}">
            <div class="${styles.backdrop}" @click="${closeAction}"></div>
            <div class="${styles.popup}">
                <div class="${styles.bannerContainer}"></div>
                ${CloseButton(closeAction)}
                <div class="${styles.content}">${content}</div>
            </div>
        </div>
    `;
};
