import { IContainer } from '../components/common';
import type { ContextManager } from '../context-manager';

interface GraphQLError {
    message: string;
    locations: Array<{
        line: number;
        column: number;
    }>;
}

export interface GraphQLErrors extends Array<GraphQLError> {}

export const getContainer = (graph: Function, token: string): Promise<IContainer> =>
    graph(
        `query (@autodeclare) {
            getContainer(token: $token) {
                token
                options
                pages {
                    id
                    ordering
                    layout
                    slots {
                        slotType
                        blocks {
                            blockType
                            options
                        }
                    }
                }
            }
        }`
    )({ token }).then(({ getContainer }) => getContainer);

export enum APIActionType {
    CREATE_RECORD = 'CREATE_RECORD',
    UPDATE_RECORD = 'UPDATE_RECORD',
}

export const APIAction = (
    graph: Function,
    contextManager: ContextManager,
    actionType: APIActionType,
    params: any
): Promise<{ action: string; response: any }> =>
    graph(
        `mutation ($containerToken: String!, $params: JSONScalar) {
            wizardAction(containerToken: $containerToken, actionType: ${actionType}, params: $params) {
                action
                response
            }
        }`
    )({
        containerToken: contextManager.getContainerToken(),
        params: JSON.stringify(params),
    }).then(({ wizardAction }) => wizardAction);
