import { CallbackHandler } from '../helpers';

type StateType = string | number;

interface IStateChart {
    initial: StateType;
    states: {
        [state: StateType]: {
            on?: {
                [action: StateType]: StateType;
            };
        };
    };
}

export class StateChart<
    State extends StateType,
    Action extends StateType
> extends CallbackHandler {
    private machine: IStateChart;

    public constructor(machine: IStateChart) {
        super();
        this.machine = machine;
        this._state = this.machine.initial as State;
    }

    private _state: State;

    public get state() {
        return this._state;
    }

    public set state(value: State) {
        const shouldUpdate = this._state !== value;
        this._state = value;
        if (shouldUpdate) this.callback();
    }

    public transition(action: Action) {
        const currentStateActions = this.machine.states[this.state]?.on || {};
        const targetState = currentStateActions[action];
        this.state = (targetState === undefined ? this.state : targetState) as State;
    }
}
