import { html, TemplateResult } from 'lit-element';
import type { IBlock } from './blocks';
import styles from './layouts.css';
import { Dispatcher } from './dispatcher';

export const layouts = new Dispatcher();

export interface ISlot {
    slotType: string;
    blocks?: IBlock[];
    content?: TemplateResult[];
}

const getSlotClass = (slotType: string): string => {
    const slotClassesMap = {
        PRIMARY: styles.slotPrimary,
    };
    return slotClassesMap[slotType];
};

const renderSlots = (slots: ISlot[]): TemplateResult[] =>
    slots.map(
        (slot) => html`
            <div class="${getSlotClass(slot.slotType)}">${slot.content}</div>
        `
    );

layouts.registerComponent(
    'FULL',
    (slots: ISlot[]) => html`<div class="${styles.full}">${renderSlots(slots)}</div>`
);
