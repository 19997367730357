export type ContextFieldValue = string | number | boolean | Array<string | number>;

export interface IRecordContext {
    id?: number;
    data?: {
        [field: string]: ContextFieldValue;
    };
}

export interface IContext {
    containerToken?: string;
    record?: IRecordContext;
}

export class ContextManager {
    private context: IContext = { record: {} };

    public updateContext(newData: IContext) {
        this.context = {
            ...this.context,
            ...newData,
            record: {
                ...(this.context?.record || {}),
                ...(newData?.record || {}),
                data: {
                    ...(this.context?.record?.data || {}),
                    ...(newData?.record?.data || {}),
                },
            },
        };
    }

    public getContainerToken(): string {
        return this.context?.containerToken || '';
    }

    public getRecord(): IRecordContext {
        return this.context?.record || {};
    }

    public getRecordData(): IRecordContext {
        return this.getRecord()?.data || {};
    }

    public getDataField(fieldName: string): ContextFieldValue {
        const contextData = this.getRecordData();
        const fieldValue = contextData[fieldName] || '';
        return fieldValue;
    }
}

export const getContextManager = () => new ContextManager();
