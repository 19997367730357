import { html, customElement } from 'lit-element';
import styles from './hub-website-config.css';
import { LightElement } from './base-component';

@customElement('hub-website-config')
export class HubWebsiteConfig extends LightElement {
    render() {
        return html`
            <div class="${styles.configPanel}">
                ${this.renderColorInput('--Color-brand-primary', '#0d65a8')}
                ${this.renderColorInput('--Color-brand-secondary', '#039BE5', true)}
                ${this.renderColorInput('--Color-brand-tertiary', '#004275')}
            </div>
        `;
    }

    renderColorInput(cssVariable: string, cssColor: string, createAsTransparent = false) {
        const onColorInputChange = (e: InputEvent) => {
            const colorValue = (e.target as HTMLInputElement).value;
            this.updateGlobalVariable(cssVariable, colorValue);
            if (createAsTransparent) {
                const varTrans = `${cssVariable}-transparent`;
                const colorTrans = this.hexToRgba(colorValue, '.2');
                this.updateGlobalVariable(varTrans, colorTrans);
            }
        };
        return html`
            <label>
                ${cssVariable}
                <input type="color" value="${cssColor}" @change="${onColorInputChange}" />
            </label>
        `;
    }

    private updateGlobalVariable(cssVariable: string, colorValue: string) {
        document.querySelector('html').style.setProperty(cssVariable, colorValue);
        console.log(cssVariable, colorValue);
    }

    private hexToRgba(hex: string, alpha: string) {
        let c: Array<any> | string | any;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split('');
            if (c.length == 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = `0x${(c as string[]).join('')}`;
            return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(
                ','
            )},${alpha})`;
        }
        throw new Error('Bad Hex');
    }
}
