import { html, TemplateResult } from 'lit-element';
import classNames from 'classnames';
import styles from './layout.css';

interface INavbar {
    isOnTop: boolean;
}

export const Navbar = (options: INavbar, children: TemplateResult[]) => {
    const classes = classNames([
        styles.navbarWrapper,
        { [styles.isOnTop]: options.isOnTop },
    ]);
    return html`
        <div class="${classes}">
            <header id="navbar" class="${styles.navbar}">
                <div class="${styles.logo}"></div>
                <nav class="${styles.navigation}">${children}</nav>
            </header>
        </div>
    `;
};

interface IHero {}

export const Hero = (_options: IHero, children: TemplateResult[]) => html`
    <div class="${styles.hero}">${children}</div>
`;

interface ISectionSeparator {
    id?: string;
    sceneSide?: string;
    mobileSceneSide?: string;
    laptopImage?: string;
    noHeight?: boolean;
}

export const SectionSeparator = (options: ISectionSeparator) => {
    const classes = classNames([
        styles.sectionSeparator,
        { [styles.sectionSeparatorCollapsed]: options.noHeight },
    ]);
    return html`
        <div
            id="${options.id}"
            class="${classes}"
            data-side="${options.sceneSide}"
            data-mobile-side="${options.mobileSceneSide}"
            data-laptop-image="${options.laptopImage}"
        ></div>
    `;
};

interface ISection extends ISectionSeparator {
    align: 'left' | 'right' | 'center';
    vAlign?: 'top' | 'bottom';
    isWide?: boolean;
    isShort?: boolean;
}

export const Section = (options: ISection, children: TemplateResult[]) => {
    const alignMap = {
        left: styles.left,
        right: styles.right,
        center: styles.center,
    };
    const vAlignMap = {
        top: styles.top,
        bottom: styles.bottom,
    };
    const classes = classNames([
        styles.section,
        alignMap[options.align],
        vAlignMap[options.vAlign],
        { [styles.isContentWide]: options.isWide },
        { [styles.isContentShort]: options.isShort },
    ]);
    return html`
        <div class="${classes}">
            <div
                id="${options.id}"
                class="${styles.content}"
                data-side="${options.sceneSide}"
                data-mobile-side="${options.mobileSceneSide}"
                data-laptop-image="${options.laptopImage}"
            >
                ${children}
            </div>
        </div>
    `;
};

interface IRow {
    reverse?: boolean;
    narrow?: boolean;
    stretch?: boolean;
    vAlign?: 'top' | 'bottom';
}

export const Row = (options: IRow = {}, children: TemplateResult[]) => {
    const classes = classNames([
        styles.row,
        {
            [styles.rowReverse]: options.reverse,
            [styles.rowNarrow]: options.narrow,
            [styles.rowStretch]: options.stretch,
        },
        {
            [styles.rowAlignTop]: options.vAlign === 'top',
            [styles.rowAlignBottom]: options.vAlign === 'bottom',
        },
    ]);
    return html`<div class="${classes}">${children}</div>`;
};

interface IContentBlock {}

export const ContentBlock = (_options: IContentBlock, children: TemplateResult[]) => html`
    <div class="${styles.contentBlock}">${children}</div>
`;

export const Detail = (text: TemplateResult | TemplateResult[]) => html`
    <div class="${styles.detail}">
        <div class="${styles.detailLine}"></div>
        <div class="${styles.detailText}">${text}</div>
    </div>
`;

interface IFooterInfo {
    url: string;
    text: string;
    target?: string;
}

interface IFooter {
    privacyText: string;
    onClick: Function;
    footerInfo: IFooterInfo[];
    illustrationInfo: IFooterInfo;
}

const FooterLogo = () => html`<div class="${styles.logoFooter}"></div>`;

const FooterSection = (children: TemplateResult[]) =>
    ContentBlock({}, [html`<div class="${styles.footerSection}">${children}</div>`]);

const FooterInfo = (info: IFooterInfo) => html`
    <a href="${info.url}" target="${info.target}"> ${info.text} </a>
`;

export const Footer = (options: IFooter) => {
    const row = Section({ align: 'center', isWide: true }, [
        Row({ vAlign: 'top' }, [
            ContentBlock({}, [FooterLogo()]),
            FooterSection([
                html`
                    <a href="javascript:void(0)" @click="${options.onClick}">
                        ${options.privacyText}
                    </a>
                `,
            ]),
            FooterSection([
                html`
                    <div class="${styles.logoStem5Footer}"></div>
                    <div class="${styles.footerInfo}">
                        ${options.footerInfo.map(FooterInfo)}
                    </div>
                `,
            ]),
        ]),
    ]);
    return html`<div class="${styles.footer}">${row}</div>`;
};

interface IImageBlock {
    src: string;
    alt: string;
}

export const ImageBlock = (options: IImageBlock) => html`
    <div class="${styles.image}">
        <img src="${options.src}" alt="${options.alt}" />
    </div>
`;
